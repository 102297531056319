import React, { useEffect } from "react";
// import * as PIXI from "pixi.js";
import Parallax from "parallax-js";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const COD = ({ pageContext: { slug } }) => {
  const lang = "en";
  useEffect(() => {
    var scene = document.getElementById("scene");
    // eslint-disable-next-line
    var parallaxInstance = new Parallax(scene);
  });
  const nav = {
    initialSlideIndex: 1,
  };
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #A08C55, #AE985B, #BBA360, #AE985B, #A08C55 )",
        icons: "#c5af71",
        navClass: "cod",
      }}
      seo={{
        title: "Call of Duty: Advanced Warfare",
        headerTitle: "cod",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/cod_header.png"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/call-of-duty-advanced-warfare/",
      }}
    >
      <PortfolioHeader name="cod" height="145" />
      <section className="container-fluid cod_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Call of Duty: Advanced Warfare</h1>
              <ul>
                <li>Website</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Call of Duty is one of the most famous computer games in the
                world. Subsequent parts of the published Activision series were
                sold in hundreds of millions of copies and achieved spectacular
                commercial success, giving the game the status of one of the
                most recognizable brands in the world of games. Our task was to
                create a website promoting the game Call of Duty: Advanced
                Warfare on the Polish market. In addition to showing how the
                game looks, the main purpose of the site was to redirect the
                users to online stores
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="cod_section_3">
        <div className="scroll_wrapper laptop">
          <span />
          <div className="img_wrapper">
            <img
              src={require("../../../assets/img/portfolio/cod_entire_page.jpg")}
              alt=""
            />
          </div>
        </div>
        <img src={require("../../../assets/img/portfolio/cod_bg.png")} alt="" />
        <div id="scene" className="debris__container">
          <img
            data-invert-x
            data-invert-y
            data-depth="0.1"
            src={require("../../../assets/img/portfolio/cod_debris.png")}
            alt=""
            className="debris"
          />
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default COD;
